import React, { useState } from "react";
import "./Navbar.css";
import { Outlet } from "react-router-dom";
import İkinLogo from "../Assets/2n-logo.png";
import YerliUretim from "../Assets/yerli-uretim-logosu-kirmizi.png";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const [isAct, setIsAct] = useState(false);
  const [isActi, setIsActi] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const [translateY, setTranslateY] = useState(0);

  const handleButtonClick = () => {
    document.body.style.overflow = isActive ? "scroll" : "hidden";
    setIsActive(!isActive);
  };
  const handleartiClick = () => {
    setIsAct(!isAct);
  };
  const handleikiartiClick = () => {
    setIsActi(!isActi);
  };

  const { t, i18n } = useTranslation();
  const clickHandle = (lang) => {
    i18n.changeLanguage(lang);
    setTranslateY(lang === "tr" ? 0 : 7);
  };

  const body = document.body;
  let lastScroll = 0;

  window.addEventListener("scroll", () => {
    const currentScroll = window.pageYOffset;
    if (currentScroll <= 90) {
      body.classList.remove("scroll-up");
      return;
    }

    if (currentScroll > lastScroll && !body.classList.contains("scroll-down")) {
      body.classList.remove("scroll-up");
      body.classList.add("scroll-down");
    } else if (
      currentScroll < lastScroll &&
      body.classList.contains("scroll-down")
    ) {
      body.classList.remove("scroll-down");
      body.classList.add("scroll-up");
    }
    lastScroll = currentScroll;
  });

  return (
    <div>
      <header className="header">
        <div class="container h-full">
          <div class="wrapper h-full flex justify-between sm:gap-20">
            <div class="logo-field w-fit flex items-center">
              <a href="/" class="flex justify-start">
                <div class="image overflow-hidden h-76 md:h-50">
                  <img
                    src={İkinLogo}
                    class="w-full h-full object-contain object-center max-w-220"
                    loading="lazy"
                    alt="2n mekatronik logo"
                    aria-label="2n mekatronik logo"
                  />
                </div>
                <img
                  src={YerliUretim}
                  className="yerliuretim"
                  loading="lazy"
                  alt="Yerli Üretim"
                  aria-label="Yerli Üretim"
                />
              </a>
            </div>
            <div class="menu-field flex items-center space-x-30 ">
              <div class="menu h-full lg:absolute lg:top-full lg:left-0 lg:w-full lg:hidden">
                <ul
                  className={`menu-content h-full lg:h-auto flex items-center space-x-50 xl:space-x-30 lg:flex-col lg:space-x-0 bg-white ${
                    isActive ? "active" : ""
                  }`}
                >
                  <li class="group/has-sub-menu kurumsal dortlu-menu menu-main-item has-sub-menu relative flex flex-col justify-center lg:w-full ml50">
                    <a
                      href="/kurumsal"
                      class="flex items-center space-x-10 lg:p-10 lg:pl-30 lg:pr-50"
                    >
                      <span class="text text-22 xl:text-20 lg:text-18 text-black leading-tight duration-450 ease-samedown group-hover/has-sub-menu:text-primay-900 ">
                        {t("layout.corporate")}
                      </span>
                      <i class="fa-solid fa-chevron-down"></i>
                    </a>

                    <div
                      class="sub-trigger group/menu-icon absolute right-15 top-[6px] w-30 h-30 bg-primary justify-center items-center cursor-pointer rounded-default duration-450 ease-samedown [&amp;.active]:bg-main-300 lg:group-[&amp;.has-sub-menu]/has-sub-menu:flex"
                      onClick={handleartiClick}
                      style={{ top: "4px", right: "42px", cursor: "pointer" }}
                    >
                      <i class="fa-solid fa-plus"></i>
                    </div>

                    <div
                      className={`mega-menu change-height  absolute top-full py-30 -left-30 lg:left-0 w-full bg-white duration-450 ease-samedown opacity-0 pointer-events-none translate-y-20 group-hover/has-sub-menu:opacity-100 group-hover/has-sub-menu:visible group-hover/has-sub-menu:pointer-events-auto group-hover/has-sub-menu:translate-y-0 before:absolute before:left-0 before:-top-65 before:h-65 before:w-full before:bg-transparent before:duration-450 before:ease-samedown group-hover/has-sub-menu:before:-top-45 group-hover/has-sub-menu:before:h-45 min-w-[360px] lg:relative lg:p-0 lg:hidden lg:opacity-100 lg:visible lg:before:hidden lg:translate-y-0 shadow-lg lg:shadow-none lg:pl-30  ${
                        isAct ? "act" : ""
                      }`}
                    >
                      <div class="">
                        <ul class="scrollbar max-h-[580px] lg:max-h-max lg:space-y-0 !pr-0">
                          <li>
                            <a
                              href="/kurumsal"
                              class="flex group/sub-link p-15 pl-50 lg:p-15 duration-450 hover:bg-primary-900"
                            >
                              <div class="text text-[20px] ">
                                {t("layout.aboutus")}
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="/kurumsal"
                              class="flex scrollable-link group/sub-link p-15 pl-50 lg:p-15 duration-450 hover:bg-primary-900"
                            >
                              <div class="text text-[20px] lg:text-18 sm:text-[16px] leading-tight text-[#111111]/85 duration-450 whitespace-nowrap group-hover/sub-link:text-white group-hover/sub-link:translate-x-1">
                                {t("layout.visionmision")}
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="/sertifikalar"
                              class="flex group/sub-link p-15 pl-50 lg:p-15 duration-450 hover:bg-primary-900"
                            >
                              <div class="text text-[20px] lg:text-18 sm:text-[16px] leading-tight text-[#111111]/85 duration-450 whitespace-nowrap group-hover/sub-link:text-white group-hover/sub-link:translate-x-1">
                                {t("layout.certificates")}
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="/kvkk-aydinlatma-metni"
                              class="flex group/sub-link p-15 pl-50 lg:p-15 duration-450 hover:bg-primary-900"
                            >
                              <div class="text text-[20px] lg:text-18 sm:text-[16px] leading-tight text-[#111111]/85 duration-450 whitespace-nowrap group-hover/sub-link:text-white group-hover/sub-link:translate-x-1">
                                {t("layout.kvkk")}
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>

                  <li class="group/has-sub-menu kurumsal menu-main-item has-sub-menu relative flex flex-col justify-center lg:w-full">
                    <a
                      href="/hizmetlerimiz"
                      class="flex items-center space-x-10 lg:p-10 lg:pl-30 lg:pr-50"
                    >
                      <span class="text text-22 text-20 text-18 text-black leading-tight duration-450 ease-samedown group-hover/has-sub-menu:text-primay-900">
                        {t("layout.services")}
                      </span>
                      <i class="fa-solid fa-chevron-down"></i>
                    </a>

                    <div
                      class="sub-trigger group/menu-icon absolute right-15 top-[6px] w-30 h-30 bg-primary justify-center items-center cursor-pointer rounded-default duration-450 ease-samedown [&amp;.active]:bg-main-300 lg:group-[&amp;.has-sub-menu]/has-sub-menu:flex"
                      style={{ top: "4px", right: "42px", cursor: "pointer" }}
                      onClick={handleikiartiClick}
                    >
                      <i class="fa-solid fa-plus"></i>
                    </div>

                    <div
                      className={`mega-menu change-height  absolute top-full py-30 -left-30 lg:left-0 w-full bg-white duration-450 ease-samedown opacity-0 pointer-events-none translate-y-20 group-hover/has-sub-menu:opacity-100 group-hover/has-sub-menu:visible group-hover/has-sub-menu:pointer-events-auto group-hover/has-sub-menu:translate-y-0 before:absolute before:left-0 before:-top-65 before:h-65 before:w-full before:bg-transparent before:duration-450 before:ease-samedown group-hover/has-sub-menu:before:-top-45 group-hover/has-sub-menu:before:h-45 min-w-[360px] lg:relative lg:p-0 lg:hidden lg:opacity-100 lg:visible lg:before:hidden lg:translate-y-0 shadow-lg lg:shadow-none lg:pl-30  ${
                        isActi ? "act" : ""
                      }`}
                    >
                      <div class="content bg-white rounded-[2px] space-y-20 lg:space-y-5 lg:shadow-none">
                        <ul class="scrollbar max-h-[580px] lg:max-h-max lg:space-y-0 !pr-0">
                          <li>
                            <a
                              href="/ram-filtresi"
                              class="flex group/sub-link p-15 pl-50 lg:p-15 duration-450 hover:bg-primary-900"
                            >
                              <div class="text text-[20px] lg:text-18 sm:text-[16px] leading-tight text-[#111111]/85 duration-450 whitespace-nowrap group-hover/sub-link:text-white group-hover/sub-link:translate-x-1">
                                {t("layout.fluegas")}
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="/ram-filtresi"
                              class="flex group/sub-link p-15 pl-50 lg:p-15 duration-450 hover:bg-primary-900"
                            >
                              <div class="text text-[20px] lg:text-18 sm:text-[16px] leading-tight text-[#111111]/85 duration-450 whitespace-nowrap group-hover/sub-link:text-white group-hover/sub-link:translate-x-1">
                                {t("layout.machine")}
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="/isi-geri-kazanimi"
                              class="flex group/sub-link p-15 pl-50 lg:p-15 duration-450 hover:bg-primary-900"
                            >
                              <div class="text text-[20px] lg:text-18 sm:text-[16px] leading-tight text-[#111111]/85 duration-450 whitespace-nowrap group-hover/sub-link:text-white group-hover/sub-link:translate-x-1">
                                {t("layout.recovery")}
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li class="group/has-sub-menu lg:relative lg:w-full">
                    <a
                      href="/referanslarimiz"
                      class="flex items-center space-x-10 lg:p-10 lg:pl-30 lg:pr-50"
                    >
                      <span class="text text-22 xl:text-20 lg:text-18 text-black leading-tight duration-450 ease-samedown group-hover/has-sub-menu:text-primay-900">
                        {t("layout.references")}
                      </span>
                      <i class="icon icon-chevron-bottom text-[12px] h-[12px] text-white leading-none duration-450 ease-samedown group-hover/has-sub-menu:rotate-180 group-hover/has-sub-menu:text-primay-900 group-[&amp;.has-sub-menu]/has-sub-menu:block hidden lg:!hidden"></i>
                    </a>
                  </li>
                  <li class="group/has-sub-menu lg:relative lg:w-full">
                    <a
                      href="/haberler"
                      class="flex items-center space-x-10 lg:p-10 lg:pl-30 lg:pr-50"
                    >
                      <span class="text text-22 xl:text-20 lg:text-18 text-black leading-tight duration-450 ease-samedown group-hover/has-sub-menu:text-primay-900">
                        {t("layout.news")}
                      </span>
                      <i class="icon icon-chevron-bottom text-[12px] h-[12px] text-white leading-none duration-450 ease-samedown group-hover/has-sub-menu:rotate-180 group-hover/has-sub-menu:text-primay-900 group-[&amp;.has-sub-menu]/has-sub-menu:block hidden lg:!hidden"></i>
                    </a>
                  </li>
                  <li class="group/has-sub-menu lg:relative lg:w-full">
                    <a
                      href="/iletisim"
                      class="flex items-center space-x-10 lg:p-10 lg:pl-30 lg:pr-50"
                    >
                      <span class="text text-22 xl:text-20 lg:text-18 text-black leading-tight duration-450 ease-samedown group-hover/has-sub-menu:text-primay-900">
                        {t("layout.contact")}
                      </span>
                      <i class="icon icon-chevron-bottom text-[12px] h-[12px] text-white leading-none duration-450 ease-samedown group-hover/has-sub-menu:rotate-180 group-hover/has-sub-menu:text-primay-900 group-[&amp;.has-sub-menu]/has-sub-menu:block hidden lg:!hidden"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="other-menu-field flex items-center space-x-30 sm:space-x-15">
                <div class="split  shrink-0 w-[1px] h-30 bg-black/10 "></div>

                <div class="language-selector relative group/language-select">
                  <div class="current-langage flex items-center cursor-pointer gap-5">
                    {/* <i class="icon icon-earth text-[20px] h-[20px] text-white block  fa-solid fa-earth-americas" style={{fontSize:'20px'}}></i> */}

                    <span
                      style={{ color: "black" }}
                      class="text text-20  lg:text-18 text-black leading-tight font-gilroy duration-450 ease-samedown"
                    >
                      {i18n.language}
                    </span>

                    <i class="icon text-white block leading-none group-hover/language-select:text-primay-900 duration-450 ease-samedown">
                      <svg
                        width="14"
                        height="22"
                        viewBox="0 0 14 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.5"
                          y="0.5"
                          width="13"
                          height="21"
                          rx="6.5"
                          stroke="black"
                        ></rect>
                        <rect
                          class="duration-450 icon-language"
                          style={{ transform: `translateY(${translateY}px)` }}
                          x="4"
                          y="4"
                          width="6"
                          height="6"
                          rx="3"
                          fill="black"
                        ></rect>
                      </svg>
                    </i>
                  </div>
                  <div class="language-menu absolute top-80 bg-white py-15 left-[50%] translate-x-[-50%] ">
                    <ul class="space-y-10">
                      <li>
                        <a
                          href="javascript:;"
                          class="group/language hover:bg-primary-900 px-20 py-10 duration-450"
                          onClick={() => clickHandle("tr")}
                        >
                          <span class="text text-18 text-black leading-tight duration-450 group-hover/language:text-white">
                            TR
                          </span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="javascript:;"
                          class="group/language hover:bg-primary-900 px-20 py-10 duration-450"
                          onClick={() => clickHandle("en")}
                        >
                          <span class="text text-18 text-black leading-tight duration-450 group-hover/language:text-white">
                            EN
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="mobile-menu-selector" onClick={handleButtonClick}>
                  <div className={`burger ${isActive ? "open" : ""}`}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default Navbar;
