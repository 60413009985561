import React from "react";
import "./HizmetlerP.css";
import Breadcrumps from "../Breadcrumps/Breadcrumps";
import BacaFiltre from "../Assets/baca-filtre-sistemleri.png";
import İsiSistemleri from "../Assets/isi-sistemleri.png";
import OzelMakine from "../Assets/ozel-makina.jpg";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const HizmetlerP = () => {
  const { t, i18n } = useTranslation();
  return (
    <HelmetProvider>
      <>
        <Helmet>
          <title>Hizmetlerimiz | 2N Mekatronik®</title>
          <meta
            name="description"
            content="Kaliteli hizmetler sunarken çevreye zarar vermemek, geri dönüşüm sağlayan hizmetler sunmak, atık sıcak havanızın geri dönüşüm ile işletmenize enerji ..."
          ></meta>
          <meta
            property="og:description"
            content="Kaliteli hizmetler sunarken çevreye zarar vermemek, geri dönüşüm sağlayan hizmetler sunmak, atık sıcak havanızın geri dönüşüm ile işletmenize enerji ..."
          ></meta>
          <meta
            property="og:url"
            content="https://www.2nmekatronik.com.tr/hizmetler/"
          ></meta>
        </Helmet>
        <Breadcrumps
          baslik={t("yourFutureWeProtect")}
          page={t("pages.services")}
        />
        <div className="hizmetler-p">
          <div className="container">
            <div className="hizmet-cards g3">
              <a href="/ram-filtresi">
                <div className="hizmet-card">
                  <h3 className="bslk-txt">{t("serviceTitle1")}</h3>
                  <div className="svc-img">
                    <img
                      src={BacaFiltre}
                      alt="2n mekatronik Baca Gazı Filtresi Sistemleri"
                      aria-label="Baca Gazı Filtresi Sistemleri"
                    />
                  </div>
                </div>
              </a>

              <a href="/isi-geri-kazanimi">
                <div className="hizmet-card">
                  <div className="svc-img">
                    <img
                      src={İsiSistemleri}
                      alt="2n mekatronik Isı Geri Kazanım Sistemleri"
                      aria-label="Isı Geri Kazanım Sistemleri"
                    />
                  </div>
                  <h3>{t("serviceTitle2")}</h3>
                </div>
              </a>
              <a href="">
                <div className="hizmet-card">
                  <div className="svc-img">
                    <img
                      src={OzelMakine}
                      alt="2n mekatronik Isı Geri Kazanım Sistemleri"
                      aria-label="Isı Geri Kazanım Sistemleri"
                    />
                  </div>
                  <h3>{t("serviceTitle3")}</h3>
                </div>
              </a>
            </div>
          </div>
        </div>
      </>
    </HelmetProvider>
  );
};

export default HizmetlerP;
