import React, { useLayoutEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import "./Service.css";

import Clouds from "../Assets/clouds.png";
import BacaFiltre from "../Assets/endustriyel.png";
import İsiSistemleri from "../Assets/isigerikazanim.jpg";
import Bacagazi from "../Assets/111.png";
import FiltreIcon from "../Assets/filtre-icon2.png";
import IsiIcon from "../Assets/isi-icon.png";
import YikamaIcon from "../Assets/yikama-icon.png";
import RamBaca from "./RamBaca";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-cards";
import "swiper/css/parallax";
import { EffectCards, Pagination, Parallax } from "swiper/modules";

import { useTranslation } from "react-i18next";

const Service = () => {
  const { t, i18n } = useTranslation();
  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    let mm = gsap.matchMedia();
    mm.add("(min-width: 992px)", () => {
      ScrollTrigger.create({
        start: "top 10%",
        end: "bottom bottom ",
        trigger: ".clouds",
        id: "pinned",

        endTrigger: ".altservice",
        invalidateOnRefresh: true,
        // markers:true
      });
    });
    return () => mm.revert();
  });
  //   açılan sayfa
  useGSAP(() => {
    gsap.set(".altservice", {});
    gsap.to(".altservice", {
      scrollTrigger: {
        trigger: ".service",
        start: "top 88%",
        end: "top",
        scrub: 1,
        once: true,
      },
      borderRadius: 5,
      scale: 1,
      duration: 2,
      ease: "power2.out",
    });
  });

  // bulutlar
  useGSAP(() => {
    gsap.set(".clouds", { scale: 0.8, x: 150, opacity: 0.2 });
    gsap.to(".clouds", {
      scrollTrigger: {
        trigger: ".service",
        start: "-30% center",
        end: "bottom",
        scrub: 2,
        id: "pin",
        toggleActions: "play pause",

        //   markers: true,
      },
      translateX: -500,
      duration: 5,
      opacity: 0.8,
      ease: "power2.out",
    });
  });

  return (
    <div className="service mt4 mx-auto">
      <div className="altservice">
        <div className="container ">
          <div className="clouds">
            <img src={Clouds} alt="" />
          </div>
          <div>
            <h2 className="bslk">{t("whatWeDo")}</h2>
            <div className="">
              <Swiper
                pagination={{
                  type: "progressbar",
                }}
                effect={"cards"}
                grabCursor={true}
                modules={[EffectCards, Pagination]}
                className="mySwiper"
                breakpoints={{
                  769: {
                    effect: "slide", // cards efektini responsive olarak değiştirme
                    grabCursor: false, // responsive olarak grabCursor özelliğini kapatma
                    modules: [Pagination], // responsive olarak sadece Pagination modülünü kullanma
                    navigation: false, // responsive olarak navigasyon öğesini kapatma
                  },
                  // Diğer breakpoint'leri buraya ekleyebilirsiniz...
                }}
                slidesPerView={1}
                initialSlide={1}
                allowSlideNext={false}
                loop={true}
              >
                <div className="swiper ">
                  <ul className="items-center">
                    <li>
                      <SwiperSlide>
                        <img className="iconimg2" src={FiltreIcon} alt="" />
                        <h3 className="my1 bslk-txt">{t("serviceTitle1")}</h3>
                        <div className="svc-img">
                          <img
                            src={Bacagazi}
                            alt="2n mekatronik Baca Gazı Filtresi Sistemleri"
                            aria-label="Baca Gazı Filtresi Sistemleri"
                          />
                        </div>
                        <div className="svc-content">
                          <p className="svc-txt">
                            <a href="/ram-filtresi">
                              <i className="fa-solid fa-arrow-right svc-icon"></i>{" "}
                              {t("economizerTitle")}
                            </a>
                          </p>
                          <p className="svc-txt">
                            <a href="/ram-filtresi">
                              <i className="fa-solid fa-arrow-right svc-icon"></i>{" "}
                              {t("ramTitle")}
                            </a>
                          </p>
                          <p className="svc-txt">
                            <a href="/ram-filtresi">
                              <i className="fa-solid fa-arrow-right svc-icon"></i>{" "}
                              {t("dryingTitle")}
                            </a>
                          </p>
                          <p className="svc-txt">
                            <a href="/ram-filtresi">
                              <i className="fa-solid fa-arrow-right svc-icon"></i>{" "}
                              {t("rotationTitle")}
                            </a>
                          </p>
                        </div>

                        <img className="iconimg" src={FiltreIcon} alt="" />
                      </SwiperSlide>
                    </li>
                    <li>
                      <SwiperSlide>
                        <img className="iconimg2" src={IsiIcon} alt="" />
                        <h3 className="my1 bslk-txt">{t("serviceTitle2")}</h3>
                        <div className="svc-img">
                          <img
                            src={İsiSistemleri}
                            alt="2n mekatronik Isı Geri Kazanım Sistemleri"
                            aria-label="Isı Geri Kazanım Sistemleri"
                          />
                        </div>
                        <div className="svc-content">
                          <p className="svc-txt">
                            <a href="/ram-filtresi">
                              <i className="fa-solid fa-arrow-right svc-icon"></i>{" "}
                              {t("economizerTitle")}
                            </a>
                          </p>
                          <p className="svc-txt">
                            <a href="/ram-filtresi">
                              <i className="fa-solid fa-arrow-right svc-icon"></i>{" "}
                              {t("ramTitle")}
                            </a>
                          </p>
                          <p className="svc-txt">
                            <a href="/ram-filtresi">
                              <i className="fa-solid fa-arrow-right svc-icon"></i>{" "}
                              {t("dryingTitle")}
                            </a>
                          </p>
                          <p className="svc-txt">
                            <a href="/ram-filtresi">
                              <i className="fa-solid fa-arrow-right svc-icon"></i>{" "}
                              {t("rotationTitle")}
                            </a>
                          </p>
                        </div>
                        <img className="iconimg" src={IsiIcon} alt="" />
                      </SwiperSlide>
                    </li>
                    <li>
                      <SwiperSlide>
                        <img className="iconimg2" src={YikamaIcon} alt="" />
                        <h3 className="my1 bslk-txt">{t("serviceTitle3")}</h3>
                        <div className="svc-img">
                          <img
                            src={BacaFiltre}
                            alt="2n mekatronik Diskontinü Ön Yıkama Sistemleri"
                            aria-label="Diskontinü Ön Yıkama Sistemleri"
                          />
                        </div>
                        <div className="svc-content">
                          <p className="svc-txt">{t("description")}</p>
                          <a
                            className="btn"
                            style={{ color: "white" }}
                            href="/hizmetlerimiz"
                          >
                            <span>Daha Fazla</span>
                          </a>
                        </div>
                        <img className="iconimg" src={YikamaIcon} alt="" />
                      </SwiperSlide>
                    </li>
                  </ul>
                </div>
              </Swiper>
              {/* <div >
                        <h3>Baca Gazı Filtre Sistemleri</h3>
                      </div>
                      <div>
                        <h3>Isı Geri Kazanım Sistemleri</h3>
                      </div>
                      <div>
                        <h3>Diskontinu Ön Yıkama Sistemleri</h3>
                      </div> */}
            </div>
          </div>
        </div>
        <RamBaca />
      </div>
    </div>
  );
};

export default Service;
