import React from "react";
import "./Certificatep.css";

import Sertifika9001 from "../Assets/certificate/2N MEKATRONİK 9-1.png";
import Sertifika14001 from "../Assets/certificate/2N MEKATRONİK 14-1.png";
import Sertifika45001 from "../Assets/certificate/2N MEKATRONİK 45-1.png";
import Sertifika4 from "../Assets/certificate/sertifika-4.png";
import Sertifika5 from "../Assets/certificate/sertifika-5.png";
import Sertifika6 from "../Assets/certificate/sertifika-6.png";
import YerliMali from "../Assets/certificate/yerli-mali-belgesi.png";
import Patent from "../Assets/certificate/patent.png";
import { useTranslation } from "react-i18next";
import Breadcrumps from "../Breadcrumps/Breadcrumps";
import { Helmet, HelmetProvider } from "react-helmet-async";

import LightGallery from "lightgallery/react/Lightgallery.es5";

import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-autoplay.css";
import "lightgallery/css/lg-share.css";
import "lightgallery/css/lg-thumbnail.css";

import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import lgAutoplay from "lightgallery/plugins/autoplay";
import lgShare from "lightgallery/plugins/share";

const Certificatep = () => {
  const { t, i18n } = useTranslation();
  return (
    <HelmetProvider>
      <>
        <Helmet>
          <title>Sertifikalar | 2N Mekatronik®</title>
          <meta
            name="description"
            content="2N Mekatronik olarak Patentli Endüstriyel Baca Filtreleme Sistemleri hizmetini en kaliteli ve uygun fiyatlı olacak şekilde sunuyoruz. Bilgi Almak İçin Hemen Ara !0282 ..."
          ></meta>
          <meta
            property="og:description"
            content="2N Mekatronik olarak Patentli Endüstriyel Baca Filtreleme Sistemleri hizmetini en kaliteli ve uygun fiyatlı olacak şekilde sunuyoruz. Bilgi Almak İçin Hemen Ara !0282 ..."
          ></meta>
          <meta
            property="og:url"
            content="https://www.2nmekatronik.com.tr/sertifikalar/"
          ></meta>
        </Helmet>
        <Breadcrumps baslik={t("yourFutureWeProtect")} page="Sertifikalar" />
        <div className="CertificateP">
          <div className="container">
            <h2 className="bslk2 mb4">
              <span>Sertifikalar</span> / Yetkinlikler
            </h2>
            <h2 className="mb4">
              <span>ISO</span>
            </h2>
            <div className="flex">
              <div className="wrapper ">
                <LightGallery
                  speed={500}
                  plugins={[lgThumbnail, lgShare, lgAutoplay, lgZoom]}
                >
                  <a href={Sertifika9001}>
                    <img src={Sertifika9001} />
                    <p className="txt">ISO 9001 2015</p>
                  </a>
                </LightGallery>
              </div>
              <div className="wrapper">
                <LightGallery
                  speed={500}
                  plugins={[lgThumbnail, lgShare, lgAutoplay, lgZoom]}
                >
                  <a href={Sertifika14001}>
                    <img src={Sertifika14001} alt="" />
                    <p className="txt">ISO 14001 2015</p>
                  </a>
                </LightGallery>
              </div>
              <div className="wrapper">
                <LightGallery
                  speed={500}
                  plugins={[lgThumbnail, lgShare, lgAutoplay, lgZoom]}
                >
                  <a href={Sertifika45001}>
                    <img src={Sertifika45001} alt="" />
                    <p className="txt">ISO 45001 2018</p>
                  </a>
                </LightGallery>
              </div>
            </div>
            <h2 className="mb4 mt3">
              <span>AT Uygunluk </span>
            </h2>
            <div className="flex">
              <div className="wrapper">
                <LightGallery
                  speed={500}
                  plugins={[lgThumbnail, lgShare, lgAutoplay, lgZoom]}
                >
                  <a href={Sertifika4}>
                    <img src={Sertifika4} alt="" />
                    <p className="txt">Ekonomizer Filtresi Belgesi</p>
                  </a>
                </LightGallery>
              </div>
              <div className="wrapper">
                <LightGallery
                  speed={500}
                  plugins={[lgThumbnail, lgShare, lgAutoplay, lgZoom]}
                >
                  <a href={Sertifika5}>
                    <img src={Sertifika5} alt="" />
                    <p className="txt">Atık Baca Gazları Filtresi Belgesi</p>
                  </a>
                </LightGallery>
              </div>
            </div>
            <h2 className="mb4 mt3">
              <span>Tescil & Patent</span>
            </h2>
            <div className="flex">
              <div className="wrapper">
                <LightGallery
                  speed={500}
                  plugins={[lgThumbnail, lgShare, lgAutoplay, lgZoom]}
                >
                  <a href={YerliMali}>
                    <img src={YerliMali} alt="" />
                    <p className="txt">Yerli Malı Belgesi</p>
                  </a>
                </LightGallery>
              </div>
              <div className="wrapper">
                <LightGallery
                  speed={500}
                  plugins={[lgThumbnail, lgShare, lgAutoplay, lgZoom]}
                >
                  <a href={Patent}>
                    <img src={Patent} alt="" />
                    <p className="txt">Patent Belgesi</p>
                  </a>
                </LightGallery>
              </div>
            </div>
          </div>
        </div>
      </>
    </HelmetProvider>
  );
};

export default Certificatep;
